/* eslint-disable max-lines */
const login = {
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    password: {
        required: 'Password field is required.',
        invalid: 'Please enter a valid password.',
    },
    verificationCode: {
        required: 'OTP field is required.',
        min: 'OTP must have 6 characters.',
    },
    fullName: {
        required: 'Full name field is required.',
        min: 'Full name must be at least 2 characters.',
        max: 'Full name should not be greater than 100 characters.',
    },
    phoneNumber: {
        required: 'Mobile number field is required.',
        matches: 'Please enter a valid mobile number. e.g 03xxxxxxxxx',
    },
    businessName: {
        required: 'Business name field is required.',
        min: 'Business name must be at least 1 character.',
        max: 'Business name should not be greater than 100 characters.',
    },
}

const forgot = {
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
}
const resetPassword = {
    password: {
        required: 'New password field is required.',
        min: 'New password field must be between 8 to 20 characters.',
        max: 'New password field must be between 8 to 20 characters.',
        mismatch: 'New password and confirm password fields do not match.',
    },
    confirmPassword: {
        required: 'Confirm password field is required.',
    },
}
const license = {
    title: {
        required: 'Title field is required.',
        min: 'Title field must be between 5 to 50 characters.',
        max: 'Title field must be between 5 to 50 characters.',
    },
    duration: {
        required: 'Duration field is required.',
    },
    noOfUsers: {
        required: 'Number of Users field is required.',
    },
    noOfProjects: {
        required: 'Number of Projects field is required.',
    },
    price: {
        required: 'Price field is required.',
    },
    status: {
        required: 'Status field is required.',
    },
}
const user = {
    fullName: {
        required: 'Full name field is required.',
        min: 'Full name field must be between 5 to 50 characters.',
        max: 'Full name field must be between 5 to 50 characters.',
    },
    firstName: {
        required: 'First name field is required.',
        min: 'First name field must be between 5 to 50 characters.',
        max: 'First name field must be between 5 to 50 characters.',
    },
    lastName: {
        required: 'Last name field is required.',
        min: 'Last name field must be between 5 to 50 characters.',
        max: 'Last name field must be between 5 to 50 characters.',
    },
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    password: {
        required: 'Password field is required.',
        min: 'Password field must be between 8 to 20 characters.',
        max: 'Password field must be between 8 to 20 characters.',
        mismatch: 'New password and confirm password fields do not match.',
    },
    status: {
        required: 'Status field is required.',
    },
    permission: {
        required: 'Atleast 1 permission is required.',
    },
    project: {
        required: 'Project field is required.',
    },
    site: {
        required: 'Member permissions should be set to at least one site.',
    },
    type: {
        required: 'Type field is required.',
    },
    title: {
        required: 'Title field is required.',
    },
}
const profile = {
    fullName: {
        required: 'Full name field is required.',
        min: 'Full name field must be between 5 to 50 characters.',
        max: 'Full name field must be between 5 to 50 characters.',
    },
    firstName: {
        required: 'First name field is required.',
        min: 'First name field must be between 5 to 50 characters.',
        max: 'First name field must be between 5 to 50 characters.',
    },
    lastName: {
        required: 'Last name field is required.',
        min: 'Last name field must be between 5 to 50 characters.',
        max: 'Last name field must be between 5 to 50 characters.',
    },
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    password: {
        required: 'Password field is required.',
        min: 'Password field must be between 8 to 20 characters.',
    },
    newPassword: {
        min: 'New password field must be between 8 to 20 characters.',
        max: 'New password field must be between 8 to 20 characters.',
        mismatch: 'New password and confirm password fields do not match.',
    },
    confirmPassword: {
        required: 'Confirm password field is required.',
    },
}
const campus = {
    campusTitle: {
        required: 'Campus name field is required.',
        min: 'Campus name must be at least 2 characters.',
        max: 'Campus name should not be greater than 90 characters.',
    },
    timezone: {
        required: 'Timezone field is required.',
        min: 'Timezone must be at least 2 characters.',
        max: 'Timezone should not be greater than 100 characters.',
    },
    division: {
        required: 'Division field is required.',
        min: 'Division must be at least 2 characters.',
        max: 'Division should not be greater than 50 characters.',
    },
    leaseType: {
        required: 'Lease type field is required.',
        invalid: 'Please select a valid lease type.',
    },
}
const building = {
    dis: {
        required: 'Building name field is required.',
        min: 'Building name must be at least 2 characters.',
        max: 'Building name should not be greater than 50 characters.',
    },
    primaryFunction: {
        required: 'Property type field is required.',
    },
    shortDescription: {
        min: 'Short description must be at least 2 characters.',
        max: 'Short description should not be greater than 50 characters.',
    },
    geoAddr: {
        required: 'Complete address field is required.',
        min: 'Complete address must be at least 2 characters.',
        max: 'Complete address should not be greater than 255 characters.',
    },
    zipCode: {
        required: 'Zip code field is required.',
        min: 'Zip code must be at least 5 characters long.',
        max: 'Zip code must be at most 10 characters long',
        invalid: 'Invalid zip code',
    },
    area: {
        invalid: 'Gross Floor Area field is invalid.',
        required: 'Gross Floor Area field is required.',
        min: 'Gross Floor Area must be at least 2 characters.',
        max: 'Gross Floor Area should not be greater than 15 characters.',
    },
    campusId: {
        required: 'Campus field is required.',
    },
    division: {
        required: 'Division field is required.',
        min: 'Division must be at least 2 characters.',
        max: 'Division should not be greater than 50 characters.',
    },
    region: {
        required: 'Region field is required.',
        min: 'Region must be at least 2 characters.',
        max: 'Region should not be greater than 50 characters.',
    },
    leaseType: {
        required: 'Lease type field is required.',
        invalid: 'Please select a valid lease type.',
    },
    longitude: {
        required: 'Longitude field is required.',
    },
    latitude: {
        required: 'Latitude field is required.',
    },
    geoCountry: {
        required: 'Country field is required.',
    },
    tz: {
        required: 'Timezone field is required.',
    },
    geoCity: {
        required: 'City field is required.',
        min: 'City must be at least 2 characters.',
        max: 'City should not be greater than 100 characters.',
    },
    geoState: {
        required: 'State field is required.',
    },
    group: {
        required: 'Group field is required.',
        min: 'Group must be at least 2 characters.',
        max: 'Group should not be greater than 100 characters.',
    },
    weatherStationRef: {
        required: 'Weather ref field is required.',
    },
    yearBuilt: {
        required: 'Year built field is required.',
        invalid: 'Please enter valid year built.',
    },
    temperature: {
        required: 'Temperature field is required.',
    },
    unit: {
        required: 'Unit field is required.',
    },
}

const meter = {
    label: {
        required: 'Label field is required.',
        min: 'Label must be at least 2 characters.',
        max: 'Label should not be greater than 100 characters.',
    },
    meterNumber: {
        required: 'Meter number field is required.',
        min: 'Meter number must be at least 2 characters.',
        max: 'Meter number should not be greater than 100 characters.',
    },
    buildingId: {
        required: 'Building field is required.',
    },
    source: {
        max: 'Source should not be greater than 50 characters.',
    },
    meterType: {
        required: 'Meter type field is required.',
    },
    limitUnit: {
        required: 'Limit unit field is required.',
    },
    highLimit: {
        max: 'High limit cannot be greater than 100000.',
    },
    limit: {
        max: 'High limit cannot be greater than 100000.',
    },
}
const model = {
    modelName: {
        required: 'Model Name field is required.',
        modelName: 'Model Name should not be greater than 150 characters.',
    },
    maxTemp: {
        max: 'Max Temp should not be greater than 999.',
        min: 'Max Temp should not be less than -999.',
        decimals: 'Max Temp can have at most 2 decimal places',
    },
    minTemp: {
        max: 'Min Temp should not be greater than 999.',
        min: 'Min Temp should not be less than -999.',
        decimals: 'Min Temp can have at most 2 decimal places',
    },

    type: {
        required: 'Model Type field is required.',
    },
    status: {
        required: 'Status field is required.',
    },
    buildingId: {
        required: 'Building field is required.',
    },
    referenceId: {
        required: 'Meter field is required.',
    },
    reference: {
        required: 'Equipment field is required.',
    },
    equipType: {
        required: 'Equip type field is required.',
    },
    point: {
        required: 'Point field is required.',
    },
    meterUnit: {
        required: 'Meter unit field is required.',
    },
    samplingPeriod: {
        required: 'Sampling period field is required.',
    },
    aggregationType: {
        required: 'Aggregation type field is required.',
    },
    periodStart: {
        required: 'Training period start field is required.',
    },
    periodEnd: {
        required: 'Training period end field is required.',
    },
    heatingChangePoint: {
        required: 'Heating change point field is required.',
    },
    coolingChangePoint: {
        required: 'Cooling change point field is required.',
    },
    subType: {
        required: 'Model subtype field is required.',
    },
    outlierIterations: {
        max: 'Outlier Iterations must be less than or equal to 5.',
    },
    scheduleId: {
        required: 'Schedule field is required.',
    },
    global_constraints: {
        required: 'Global constraints field is required.',
    },
    regularize_intercept: {
        required: 'Regularize Intercept field is required.',
    },
    fit_intercept: {
        required: 'Fit Intercept field is required.',
    },
}
const projectSummary = {
    name: {
        required: 'Name field is required.',
        min: 'Name must be at least 2 characters.',
        max: 'Name should not be greater than 100 characters.',
    },
    description: {
        max: 'Description should not be greater than 300 characters.',
    },
    type: {
        required: 'Type field is required.',
    },
    category: {
        required: 'Category field is required.',
    },
    status: {
        required: 'Status field is required.',
    },
    buildingId: {
        required: 'Location field is required.',
    },
    estimatedElectricityUsageMax:
        'Estimated electricity usage cannot be greater than 10000000000.',
    estimatedElectricityUsage:
        'Please enter valid estimated electricity usage.',
    estimatedElectricityUsageMin:
        'Estimated Electricity Usage cannot be less than 0.',
    verifiedElectricityUsage: 'Please enter valid verified electricity usage.',
    verifiedElectricityUsageMax:
        'Verified electricity usage cannot be greater than 10000000000.',
    verifiedElectricityUsageMin:
        'Verified electricity usage cannot be less than 0.',
    realizedElectricityUsage: 'Please enter valid realized electricity usage.',
    realizedElectricityUsageMax:
        'Realized electricity usage cannot be greater than 10000000000.',
    realizedElectricityUsageMin:
        'Realized electricity usage cannot be less than 0.',
    estimatedElectricityCost: 'Please enter valid estimated electricity cost.',
    estimatedElectricityCostMax:
        'Estimated electricity cost cannot be greater than 10000000000.',
    estimatedElectricityCostMin:
        'Estimated electricity cost cannot be less than 0.',
    verifiedElectricityCost: 'Please enter valid verified electricity cost.',
    verifiedElectricityCostMax:
        'Verified electricity cost cannot be greater than 10000000000.',
    verifiedElectricityCostMin:
        'Verified electricity cost cannot be less than 0.',
    realizedElectricityCost: 'Please enter valid realized electricity cost.',
    realizedElectricityCostMax:
        'Realized electricity cost cannot be greater than 10000000000.',
    realizedElectricityCostMin:
        'Realized electricity cost cannot be less than 0.',
    estimatedPeakDemand: 'Please enter valid estimated peak demand.',
    estimatedPeakDemandMax:
        'Estimated peak demand cannot be greater than 10000000000.',
    estimatedPeakDemandMin: 'Estimated peak demand cannot be less than 0.',
    verifiedPeakDemand: 'Please enter valid verified peak demand.',
    verifiedPeakDemandMax:
        'Verified peak demand cannot be greater than 10000000000.',
    verifiedPeakDemandMin: 'Verified peak demand cannot be less than 0.',
    realizedPeakDemand: 'Please enter valid realized peak demand.',
    realizedPeakDemandMax:
        'Realized peak demand cannot be greater than 10000000000.',
    realizedPeakDemandMin: 'Realized peak demand cannot be less than 0.',
    estimatedNaturalGasUsage: 'Please enter valid estimated natural gas usage.',
    estimatedNaturalGasUsageMax:
        'Natural gas usage cannot be greater than 10000000000.',
    estimatedNaturalGasUsageMin:
        'Estimated natural gas usage cannot be less than 0.',
    verifiedNaturalGasUsage: 'Please enter valid verified natural gas usage.',
    verifiedNaturalGasUsageMax:
        'Verified natural gas usage cannot be greater than 10000000000.',
    verifiedNaturalGasUsageMin:
        'Verified natural gas usage cannot be less than 0.',
    realizedNaturalGasUsage: 'Please enter valid realized natural gas usage.',
    realizedNaturalGasUsageMax:
        'Realized natural gas usage cannot be greater than 10000000000.',
    realizedNaturalGasUsageMin:
        'Realized natural gas usage cannot be less than 0.',
    estimatedNaturalGasCost: 'Please enter valid estimated natural gas cost.',
    estimatedNaturalGasCostMax:
        'Estimated natural gas cost cannot be greater than 10000000000.',
    estimatedNaturalGasCostMin:
        'Estimated natural gas cost cannot be less than 0.',
    verifiedNaturalGasCost: 'Please enter valid verified natural gas cost.',
    verifiedNaturalGasCostMax:
        'Verified natural gas cost cannot be greater than 10000000000.',
    verifiedNaturalGasCostMin:
        'Verified natural gas cost cannot be less than 0.',
    realizedNaturalGasCost: 'Please enter valid realized natural gas cost.',
    realizedNaturalGasCostMax:
        'Realized natural gas cost cannot be greater than 10000000000.',
    realizedNaturalGasCostMin:
        'Realized natural gas cost cannot be less than 0.',
    estimatedEnergyUsage: 'Please enter valid estimated energy usage.',
    estimatedEnergyUsageMax:
        'Estimated energy usage cannot be greater than 10000000000.',
    estimatedEnergyUsageMin: 'Estimated energy usage cannot be less than 0.',
    verifiedEnergyUsage: 'Please enter valid verified energy usage.',
    verifiedEnergyUsageMax:
        'Verified energy usage cannot be greater than 10000000000.',
    verifiedEnergyUsageMin: 'Verified energy usage cannot be less than 0.',
    realizedEnergyUsage: 'Please enter valid realized energy usage.',
    realizedEnergyUsageMax:
        'Realized energy usage cannot be greater than 10000000000.',
    realizedEnergyUsageMin: 'Realized energy usage cannot be less than 0.',
    estimatedEUI: 'Please enter valid estimated EUI.',
    estimatedEUIMax: 'Estimated EUI cannot be greater than 10000000000.',
    estimatedEUIMin: 'Estimated EUI cannot be less than 0.',
    verifiedEUI: 'Please enter valid verified EUI.',
    verifiedEUIMax: 'Verified EUI cannot be greater than 10000000000.',
    verifiedEUIMin: 'Verified EUI cannot be less than 0.',
    realizedEUI: 'Please enter valid realized EUI.',
    realizedEUIMax: 'Realized EUI cannot be greater than 10000000000.',
    realizedEUIMin: 'Realized EUI cannot be less than 0.',
    estimatedCO2Equivalent: 'Please enter valid estimated cO2 Equivalent.',
    estimatedCO2EquivalentMax:
        'Estimated cO2 Equivalent cannot be greater than 10000000000.',
    estimatedCO2EquivalentMin:
        'Estimated cO2 Equivalent cannot be less than 0.',
    verifiedCO2Equivalent: 'Please enter valid verified cO2 Equivalent.',
    verifiedCO2EquivalentMax:
        'Verified cO2 Equivalent cannot be greater than 10000000000.',
    verifiedCO2EquivalentMin: 'Verified cO2 Equivalent cannot be less than 0.',
    realizedCO2Equivalent: 'Please enter valid realized cO2 Equivalent.',
    realizedCO2EquivalentMax:
        'Realized cO2 Equivalent cannot be greater than 10000000000.',
    realizedCO2EquivalentMin: 'Realized cO2 Equivalent cannot be less than 0.',

    estimatedTotalCostSavings:
        'Please enter valid estimated total cost savings.',
    estimatedTotalCostSavingsMax:
        'Estimated total cost savings cannot be greater than 10000000000.',
    estimatedTotalCostSavingsMin:
        'Estimated total cost savings cannot be less than 0.',
    verifiedTotalCostSavings: 'Please enter valid verified total cost savings.',
    verifiedTotalCostSavingsMax:
        'Verified total cost savings cannot be greater than 10000000000.',
    verifiedTotalCostSavingsMin:
        'Verified total cost savings cannot be less than 0.',
    realizedTotalCostSavings: 'Please enter valid realized total cost savings.',
    realizedTotalCostSavingsMax:
        'Realized total cost savings cannot be greater than 10000000000.',
    realizedTotalCostSavingsMin:
        'Realized total cost savings cannot be less than 0.',

    estimatedMeasureCost: 'Please enter valid estimated measure cost.',
    estimatedMeasureCostMax:
        'Estimated measure cost cannot be greater than 10000000000.',
    estimatedMeasureCostMin: 'Estimated measure cost cannot be less than 0.',
    verifiedMeasureCost: 'Please enter valid verified measure cost.',
    verifiedMeasureCostMax:
        'Verified measure cost cannot be greater than 10000000000.',
    verifiedMeasureCostMin: 'Verified measure cost cannot be less than 0.',
    estimatedUtilityIncentive:
        'Please enter valid estimated utility incentive.',
    estimatedUtilityIncentiveMax:
        'Estimated utility incentive cannot be greater than 10000000000.',
    estimatedUtilityIncentiveMin:
        'Estimated utility incentive cannot be less than 0.',
    verifiedUtilityIncentive: 'Please enter valid verified  utility incentive.',
    verifiedUtilityIncentiveMax:
        'Verified utility incentive cannot be greater than 10000000000.',
    verifiedUtilityIncentiveMin:
        'Verified utility incentive cannot be less than 0.',
    estimatedOutOfPocketCost:
        'Please enter valid estimated Out-of-Pocket Cost.',
    estimatedOutOfPocketCostMax:
        'Estimated Out-of-Pocket Cost cannot be greater than 10000000000.',
    estimatedOutOfPocketCostMin:
        'Estimated Out-of-Pocket Cost cannot be less than 0.',
    verifiedOutOfPocketCost: 'Please enter valid verified Out-of-Pocket Cost.',
    verifiedOutOfPocketCostMax:
        'Verified Out-of-Pocket Cost cannot be greater than 10000000000.',
    verifiedOutOfPocketCostMin:
        'Verified Out-of-Pocket Cost cannot be less than 0.',
    estimatedSimplePayback: 'Please enter valid estimated Simple Payback.',
    estimatedSimplePaybackMax:
        'Estimated Simple Payback cannot be greater than 10000000000.',
    estimatedSimplePaybackMin:
        'Estimated Simple Payback cannot cannot be less than 0.',
    verifiedSimplePayback: 'Please enter valid verified Simple Payback.',
    verifiedSimplePaybackMax:
        'Verified Simple Payback cannot be greater than 10000000000.',
    verifiedSimplePaybackMin: 'Verified Simple Payback cannot be less than 0.',

    realizedMeasureCost: 'Please enter a valid realized measure cost.',
    realizedMeasureCostMax:
        'Realized measure cost cannot be greater than 10000000000.',
    realizedMeasureCostMin: 'Realized measure cost cannot be less than 0.',
    realizedUtilityIncentive:
        'Please enter a valid realized utility incentive.',
    realizedUtilityIncentiveMax:
        'Realized utility incentive cannot be greater than 10000000000.',
    realizedUtilityIncentiveMin:
        'Realized utility incentive cannot be less than 0.',
    realizedOutOfPocketCost:
        'Please enter a valid realized Out-of-Pocket Cost.',
    realizedOutOfPocketCostMax:
        'Realized Out-of-Pocket Cost cannot be greater than 10000000000.',
    realizedOutOfPocketCostMin:
        'Realized Out-of-Pocket Cost cannot be less than 0.',
    realizedSimplePayback: 'Please enter a valid realized Simple Payback.',
    realizedSimplePaybackMax:
        'Realized Simple Payback cannot be greater than 10000000000.',
    realizedSimplePaybackMin: 'Realized Simple Payback cannot be less than 0.',
}
const addIndependentVariable = {
    name: {
        required: 'Name field is required.',
        min: 'Name must be at least 2 characters.',
        max: 'Name should not be greater than 100 characters.',
    },
    kind: {
        required: 'Data Function field is required.',
    },
    aggregationType: {
        required: 'Aggregation type field is required.',
    },
    buildingId: {
        required: 'Building field is required.',
    },
    reference: {
        required: 'Equipment field is required.',
    },
    equipType: {
        required: 'Equip type field is required.',
    },
    point: {
        required: 'Point field is required.',
    },
}
const schedule = {
    name: {
        required: 'Schedule name field is required.',
        min: 'Schedule name must be at least 2 characters.',
        max: 'Schedule name should not be greater than 100 characters.',
    },
    type: {
        required: 'Type field is required.',
        invalid: 'Please select a valid option.',
    },
    building: {
        required: 'Building field is required.',
    },
    startDate: {
        required: 'Date field is required.',
    },
    endDate: {
        required: 'End date field is required.',
    },
    startTime: {
        required: 'Start time field is required.',
    },
    endTime: {
        required: 'End time field is required.',
    },
    weekdayType: {
        required: 'Custom type field is required.',
        invalid: 'Please select a valid option.',
    },
    models: {
        required: 'Model field is required.',
    },
}
const report = {
    title: {
        required: 'Report title field is required.',
        min: 'Report title must be at least 2 characters.',
        max: 'Report title should not be greater than 100 characters.',
    },
    building: {
        required: 'Building field is required.',
    },
    startDate: {
        required: 'Date field is required.',
    },
    endDate: {
        required: 'End date field is required.',
    },
    type: {
        required: 'Report type field is required.',
        invalid: 'Please select a valid report type.',
    },
}
const tracking = {
    buildingId: {
        required: 'Building field is required.',
    },
    estimatedYear: {
        required: 'Net zero target year field is required.',
    },
    weekdayType: {
        required: 'Custom type field is required.',
        invalid: 'Please select a valid option.',
    },
}
const conversation = {
    title: {
        required: 'Title field is required.',
        max: 'Title should not be greater than 200 characters.',
    },
}
const importMeterData = {
    type: {
        required: 'Data type field is required.',
    },
    unit: {
        required: 'Unit field is required.',
    },
    file: {
        required: 'Please attach a csv, xls or xlsx file.',
    },
}
const ticket = {
    dis: {
        required: 'Display filed is required.',
    },
    arcType: {
        required: 'Type filed is required.',
    },
    arcState: {
        required: 'State filed is required.',
    },
}
export {
    report,
    login,
    forgot,
    resetPassword,
    license,
    user,
    profile,
    campus,
    building,
    meter,
    model,
    schedule,
    projectSummary,
    tracking,
    conversation,
    importMeterData,
    addIndependentVariable,
    ticket,
}
